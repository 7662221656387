export function getCurrentScriptDomain(){
    const origin = new URL(document.currentScript.src).origin;
    return origin;
}

export function getCurrentScriptLocation(){
    const pathname = new URL(document.currentScript.src).pathname;
    return pathname;
}

export function basename (path) {
    return String(path).substring(0,path.lastIndexOf('/') + 1)
}
export function up (path, amount = 1) {
    return String(path).split('/').slice(0, -amount).join('/') + '/'
}