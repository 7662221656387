import "regenerator-runtime/runtime";
import "core-js/stable";
import { basename, getCurrentScriptDomain, getCurrentScriptLocation, up } from "./utils/Url";
import React from 'react';
import ReactDOM from 'react-dom';
import QrModal from "./QrModal.jsx";
const QRCode = require('qrcode')

import './scss/app.scss'

function isMobileDevice(){
    return window.innerWidth < 1000;
}

class ArWallApp{

    API_DOMAIN = getCurrentScriptDomain() + up(getCurrentScriptLocation(), 2) + 'api/';
    
    response;
    errorMessage='';
    container;
    isLoading = true;
    isOpen = false;

    constructor(){

        this.initEvents();
    }

    initEvents(){
        console.log('initEvents');
        document.addEventListener('click', this.onArButtonClick.bind(this));
    }

    async showQrCode(btn){
        
        try {
            this.isOpen = true;
            this.errorMessage = '';
            this.isLoading = true;
            this.showQrCodeModal();
            const response = await fetch(`${this.API_DOMAIN}process.php`,{
                method: 'post', 
                mode: 'cors', // no-cors, *cors, same-origin
                cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                headers: {
                    'Content-Type': 'application/json'
                // 'Content-Type': 'application/x-www-form-urlencoded',
                },
                redirect: 'follow', // manual, *follow, error ,
                body: JSON.stringify(btn.dataset)
            }).then( r => r.json());

            if(response?.success){
                this.response = response;
                this.isLoading = false;
                this.errorMessage = '';
                if(isMobileDevice()){
                    window.location.href = this.response.url;
                }else{
                    this.update();
                    this.createQrCode(response['url']);
                }
                this.update();

            }else if(this.response?.message){
                this.errorMessage = this.response.message;
                this.update();
            }
        } catch (err) {
            console.error(err);
        }
    }

    update(){
        if(this.isOpen){

            const QrModalComponent = React.createElement(QrModal, {
                response: this.response,
                store: this
            });
            ReactDOM.render(QrModalComponent, this.container);
        }
    }

    showQrCodeModal(response){
        this.container = document.createElement('div');
        const QrModalComponent = React.createElement(QrModal, {
            response: this.response,
            store: this
        });
        document.body.appendChild(this.container);
        ReactDOM.render(QrModalComponent, this.container);
        
        
    }
    
    closeModal(){
        this.isOpen = false;
        if(this.container){
            ReactDOM.unmountComponentAtNode(this.container);
        }
    }

    createQrCode(url){
        const canvas = document.getElementById('QrModalCanvas');

        if(canvas){
            QRCode.toCanvas(canvas, url, function (error) {
                if (error){
                    console.error(error)
                } else{
                    console.log('QR code created!');
                }
            });
        }

    }

    onArButtonClick(e){
        
        const btn = e.target;
        const isArBtn = Boolean(btn.dataset.image);
        if(isArBtn){
            e.preventDefault();
    
            console.log({btn, e});
            // const { image, imageHeight, imageWidth, border = 0 } = btn.dataset;

            this.showQrCode(btn);

            
            // debugger;
        }
        
    }
}

window['ArWallApp'] = new ArWallApp();

